import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function CommonSection() {
  const [activeTab, setActiveTab] = useState('social');
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = {
    social: [
      {
        question: "How does WhatsApp integration work?",
        answer: "Our platform seamlessly integrates with WhatsApp Business API, allowing you to manage customer conversations, showcase products, and process orders directly through WhatsApp. All interactions are synchronized with our dashboard for easy management."
      },
      {
        question: "Can I automate customer responses?",
        answer: "Yes! You can set up automated responses for common queries, create welcome messages, and build interactive flows. Our AI-powered system learns from interactions to provide better automated responses over time."
      },
      {
        question: "How do I set up my product catalog?",
        answer: "Setting up your catalog is simple. Upload your products through our dashboard, including images, descriptions, and prices. These automatically sync to your WhatsApp Business catalog, making them instantly available for sharing with customers."
      },
      {
        question: "What payment methods are supported?",
        answer: "We support various payment methods including M-Pesa, bank transfers, and major credit cards. All payments are processed securely within the chat interface for a seamless customer experience."
      }
    ],
    deadstock: [
      {
        question: "How does WymBee help with deadstock?",
        answer: "We connect retailers with excess inventory to stores where demand exists, helping you maintain profits without heavy discounting. Our platform analyzes sales data across locations to find the optimal redistribution opportunities."
      },
      {
        question: "What's included in the free POS system?",
        answer: "Our POS system includes comprehensive inventory tracking, sales analytics, automated reordering, and integration with our marketplace. It helps prevent deadstock accumulation while streamlining your operations."
      },
      {
        question: "How large is the retailer network?",
        answer: "Our platform connects over 500 retailers across Kenya, creating a robust marketplace for inventory redistribution. This network continues to grow, providing more opportunities for efficient stock reallocation."
      },
      {
        question: "What's the average redistribution time?",
        answer: "Most businesses find matching buyers within 48 hours. Our matching algorithm efficiently connects excess inventory with retailers showing proven demand for similar products."
      }
    ]
  };

  const tabs = [
    { id: 'social', label: 'Social Commerce', icon: '💬' },
    { id: 'deadstock', label: 'Inventory Management', icon: '📦' }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 md:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            Get quick answers about our platform features and services
          </p>

          {/* Tab Navigation */}
          <div className="flex justify-center mb-12">
            <div className="inline-flex bg-white rounded-xl p-2 shadow-sm">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => {
                    setActiveTab(tab.id);
                    setActiveIndex(null);
                  }}
                  className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-all duration-200
                    ${activeTab === tab.id 
                      ? 'bg-primary text-white shadow-md' 
                      : 'text-gray-600 hover:bg-gray-50'}`}
                >
                  <span className="text-lg">{tab.icon}</span>
                  <span className="font-medium">{tab.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* FAQ Content */}
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="space-y-4"
            >
              {faqData[activeTab].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <button
                    onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                    className="w-full bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200"
                  >
                    <div className="flex justify-between items-center w-full p-6">
                      <h3 className="text-xl font-semibold text-left">{faq.question}</h3>
                      <motion.div
                        animate={{ rotate: activeIndex === index ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <svg
                          className="w-6 h-6 text-gray-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </motion.div>
                    </div>
                    <motion.div
                      initial={false}
                      animate={{
                        height: activeIndex === index ? 'auto' : 0,
                        opacity: activeIndex === index ? 1 : 0
                      }}
                      transition={{ duration: 0.2 }}
                      className="overflow-hidden"
                    >
                      <p className="px-6 pb-6 text-gray-600 leading-relaxed text-left">
                        {faq.answer}
                      </p>
                    </motion.div>
                  </button>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
}