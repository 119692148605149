import React from 'react';
import Layout from '../components/layout/layout';
import HeroSection from '../components/help/sections/hero';
import CommonSection from '../components/help/sections/common';
import VideoSection from '../components/help/sections/video';
import ResourceSection from '../components/help/sections/resource';

export default function Help({ initialData }) {
    return (
      <Layout initialData={initialData}>
        <HeroSection />
        <CommonSection />
        <VideoSection />
        <ResourceSection />
      </Layout>
    );
}