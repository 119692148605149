import React from 'react';
import { motion } from 'framer-motion';

export default function VideoSection() {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4 md:px-8">
        <motion.div 
          className="max-w-4xl mx-auto text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            See How It Works
          </h2>
          <p className="text-gray-600 mb-12 max-w-2xl mx-auto">
            Watch our quick overview video to learn how WymBee helps businesses optimize 
            their inventory and maximize profits
          </p>
          
          <motion.div 
            className="relative aspect-video rounded-2xl overflow-hidden shadow-xl"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/P03ixQgKxwc?si=GyvMVtDSMI9IH5n_"
              title="WymBee Platform Overview"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div className="absolute inset-0 bg-primary/5 pointer-events-none" />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}