import React from 'react';
import { motion } from 'framer-motion';

export default function ResourceSection() {
  const resources = [
    {
      title: "Getting Started Guide",
      description: "Complete walkthrough of WymBee's platform features and setup process",
      icon: "📚",
      link: "/resources/getting-started.pdf",
      color: "from-blue-500/10 to-blue-600/5"
    },
    {
      title: "Inventory Optimization Playbook",
      description: "Best practices and strategies for effective inventory management",
      icon: "📊",
      link: "/resources/inventory-playbook.pdf",
      color: "from-green-500/10 to-green-600/5"
    },
    {
      title: "Success Stories",
      description: "Real stories from businesses that transformed their operations with WymBee",
      icon: "🏆",
      link: "/resources/case-studies.pdf",
      color: "from-yellow-500/10 to-yellow-600/5"
    },
    {
      title: "Integration Guide",
      description: "Technical documentation for integrating with our platform",
      icon: "🔧",
      link: "/resources/integration-guide.pdf",
      color: "from-purple-500/10 to-purple-600/5"
    }
  ];

  return (
    <section className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4 md:px-8">
        <div className="text-center max-w-2xl mx-auto mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Helpful Resources
          </h2>
          <p className="text-gray-600">
            Download our comprehensive guides and documentation to make the most of WymBee
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {resources.map((resource, index) => (
            <motion.a
              key={index}
              href={resource.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block group"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ y: -5 }}
            >
              <div className={`bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition duration-200 h-full
                bg-gradient-to-br ${resource.color}`}
              >
                <span className="text-4xl mb-4 block">{resource.icon}</span>
                <h3 className="text-xl font-semibold mb-2 group-hover:text-primary transition duration-200">
                  {resource.title}
                </h3>
                <p className="text-gray-600">{resource.description}</p>
                
                <div className="mt-4 inline-flex items-center gap-2 text-primary font-medium">
                  <span>Download PDF</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                </div>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
}